<style scoped>
.input-view{
  position: relative;
  margin-bottom: 20px;
}
.inp-ladel{
  float: left;
}
.ladel-span{
  font-size: 15px;
  line-height: 40px;
  padding: 0 10px;
  position: relative;
}
.inp-ladel-required::before {
  content: "*";
  color: #ff4136;
  line-height: 18px;
  font-size: 18px;
  position: absolute;
  left: -1px;
  top: 50%;
  margin-top: -6px;
}
.container{
  padding-left: 10px;
  margin-left: 90px;
  overflow: hidden;
}
.radio {
  margin-right: 30px;
  min-height: 30px;
  line-height: 18px;
  padding-left: 28px;
  padding-top: 5px;
  line-height: 20px;
  position: relative;
  float: left;
  margin-bottom: 20px;
}
.radio-label{
  cursor: pointer;
}
.switch-disabled{
  cursor: not-allowed;
  opacity: 0.5;
}
.radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.radio input[type="radio"] + .radio-label:before {
  content: '';
  background: #f4f4f4;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 6px;
  left: 0;
  text-align: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}
.radio input[type="radio"]:checked + .radio-label:before {
  background-color: #3197EE;
  box-shadow: inset 0 0 0 4px #f4f4f4;
}
.radio input[type="radio"]:focus + .radio-label:before {
  outline: none;
  border-color: #3197EE;
}
.radio input[type="radio"]:disabled + .radio-label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
}
.radio input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
}
.err-hint{
  position: absolute;
  top: 100%;
  left: 95px;
  color: #ff4136;
  font-size: 12px;
  line-height: 12px;
}
</style>

<template>
  <div class="input-view" :style="componentStyle">
    <div class="inp-ladel" :style="inputLabelStyle">
      <span v-if="inputLabel" class="ladel-span" :class="{'inp-ladel-required':required}">{{inputLabel}}:</span>
    </div>
    <div ref="container" class="container" :style="inputContStyle">
      <div class="radio" v-for="(item, index) of list" :key="index" :style="radioStyle">
        <input :id="'radio-' + (index + 1)" name="radio" v-model="radioVal" :value="item.value" :disabled="item.disabled || disabled" type="radio">
        <label :for="'radio-' + (index + 1)" class="radio-label" :class="{'switch-disabled':item.disabled || disabled}">{{item.title}}</label>
      </div>
      <div v-if="hint" class="err-hint" :style="errStyle">至少要选一个</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioInput',
  data () {
    return {
      pass: false,
      hint: false,
      inputType: '', // 类型
      inputHeight: '', // 高度
      inputLabel: '', // 左边文字
      radioVal: '',
      inputLabelStyle: '', // 左边宽度-样式
      inputContStyle: '', // input框样式
      componentStyle: '', // 整个组件的宽度
      radioList: [],
      radioStyle: '',
      errStyle: ''
    }
  },
  components: {},
  props: {
    value: {
      default: ''
    },
    width: {
      type: Number
    },
    marginBottom: {
      type: Number,
      default: 20
    },
    inline: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: Number,
      default: 90
    },
    labelAlign: {
      type: String,
      default: 'left'
    },
    height: {
      type: Number,
      default: 40
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    row: {
      type: Number,
      default: 0
    },
    list: {
      default: ''
    },
    // 下边是验证所用
    prop: {
      type: String,
      default: ''
    }
  },
  watch: {
    radioVal: function (n, o) {
      this.$emit('input', n)
      this.pass = true
      this.hint = false
    },
    '$props.value': function (n, o) {
      this.radioVal = n
    }
  },
  methods: {
    // 初始化数据
    initialData () {
      this.radioVal = this.$props.value
      this.inputLabel = this.$props.label
      if (this.$props.width) {
        this.componentStyle = `width: ${this.$props.width}px;margin-bottom: ${this.$props.marginBottom}px;`
      }
      if (this.$props.inline) {
        this.componentStyle = this.componentStyle + 'display: inline-block;'
      }
      const contStyle = `margin-left: ${this.$props.labelWidth}px;min-height: ${this.$props.height}px;padding-top: ${(this.$props.height - 30) / 2}px;`
      const labelStyle = `width: ${this.$props.labelWidth}px;line-height: ${this.$props.height}px;text-align: ${this.$props.labelAlign};`
      this.inputLabelStyle = labelStyle
      this.inputContStyle = contStyle
    },
    inputVerifyControl (val, type) {
      if (val.length < 1) {
        if (this.$props.required) {
          this.pass = false
          this.hint = true
        } else {
          this.pass = true
          this.hint = false
        }
      } else {
        this.pass = true
      }
      this.$emit('input', val)
    }
  },

  created () {
    this.initialData()
  },

  mounted () {
    const width = this.$refs.container.offsetWidth - 10
    if (this.$props.row > 0) {
      this.radioStyle = `width: ${width / this.$props.row}px;margin-right: 0;padding-right: 10px;`
    }
  }
}
</script>
