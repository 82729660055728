<template>
  <div class="detail" v-loading="loading">
    <Breadcrumb separator="->" :list="breadcrumbList"></Breadcrumb>
    <div class="detail-box">
      <div class="detail-title">{{articleData.title}}</div>
      <div v-html="articleData.content" style="padding: 0 25px 20px 25px;" class="vhtml"></div>
      <SwitchInput
        :disabled="radioDisabled"
        type="radio"
        :labelWidth="10"
        :row="2"
        :list="radioList"
        v-model="radioVal"
      ></SwitchInput>
      <div style="padding: 0 20px;">
        <TableInput
          v-if="isRemarks == '1'"
          label=""
          :labelWidth="0"
          :disabled="textareaDisabled"
          type="textarea"
          placeholder="请输入反馈意见内容"
          v-model="textareaVal"
        ></TableInput>
        <div class="file-box">
          <ul>
            <li v-for="(item, index) of articleData.fileVos" :key="index" class="file-li">
              <div>
                <img v-if="item.type == 'pdf'" :src="pdf" alt="" class="tb-icon">
                <img v-else-if="item.type == 'ocx'" :src="ocx" alt="" class="tb-icon">
                <img v-else-if="item.type == 'lsx'" :src="lsx" alt="" class="tb-icon">
                <img v-else :src="image" alt="" class="tb-icon">
                <span class="file-title" :title="item.remark">{{item.remark}}</span>
              </div>
              <div class="file-li-icon cursor" style="right: 75px;" @click="toLook(item.path)">
                <img :src="reviewIcon" alt="" title="预览">
              </div>
              <div class="file-li-icon cursor" @click="unload(item.path, item.remark)">
                <img :src="unlaodIcon" alt="" title="下载">
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="articleData" class="clearfix" style="padding: 20px 0 0 20px;">
        <div class="pull-left" style="width: 50%">
          <div class="detail-source">开始时间: {{articleData.startTime}}</div>
          <div class="detail-source">结束时间: {{articleData.endTime}}</div>
        </div>
        <Buttom v-if="articleData.voteUser.state == 1 && articleData.voteStatus == 2" class="btn loging" style="height: 40px;width: 200px;line-height: 40px;margin-left: 0;" :loading="submitLoading" @click.native="submitForm">
          <span>投票</span>
        </Buttom>
        <!-- <Buttom v-if="articleData.voteUser.state == 1 && articleData.voteStatus == 1" class="btn loging" style="height: 40px;width: 200px;line-height: 40px;">
          <span>未开始</span>
        </Buttom> -->
        <div v-if="articleData.voteUser.state == 1 && articleData.voteStatus == 1" class="btn disabled" style="height: 40px;width: 200px;line-height: 40px;margin-left: 0;">未开始</div>
        <div v-if="articleData.voteUser.state == 1 && articleData.voteStatus == 3" class="btn disabled" style="height: 40px;width: 200px;line-height: 40px;margin-left: 0;">已结束</div>
        <div v-if="articleData.voteUser.state == 2" class="btn disabled" style="height: 40px;width: 200px;line-height: 40px;margin-left: 0;">已投票</div>
      </div>
    </div>
  </div>
</template>

<script>
import { voteWebStatusDetails, voteWebVoteUser } from '@/api/web'
import { fileDownload } from '@/api/login.js'
import SwitchInput from '@/components/from/SwitchInput'
import TableInput from '@/components/from/TableInput'
export default {
  components: {
    SwitchInput,
    TableInput
  },
  name: 'VoteDetail',
  data () {
    return {
      image: require('../../../assets/images/icon_image.png'),
      pdf: require('../../../assets/images/icon_pdf.png'),
      ocx: require('../../../assets/images/icon_doc.png'),
      lsx: require('../../../assets/images/icon_xls.png'),
      unlaodIcon: require('../../../assets/images/unload_a.png'),
      reviewIcon: require('../../../assets/images/preview_a.png'),
      loading: false,
      submitLoading: false,
      breadcrumbList: [
        { name: '首页', path: '/' }
      ],
      articleData: '',
      radioList: [],
      radioDisabled: false,
      radioVal: '',
      textareaVal: '',
      textareaDisabled: false,
      isRemarks: '2'
    }
  },
  watch: {
    radioVal: function (n, o) {
      if (n) {
        for (let i = 0; i < this.radioList.length; i++) {
          if (this.radioList[i].value === n) {
            this.isRemarks = this.radioList[i].isRemarks
            if (this.articleData.voteUser.state !== 2) {
              this.textareaVal = ''
            }
          }
        }
      }
    }
  },
  created () {
    this.initialData()
  },
  mounted () {},
  methods: {
    unload (path, name) {
      fileDownload(path, name)
    },
    toLook (path) {
      window.open(process.env.VUE_APP_IMG_URL + path, '_blank')
    },
    submitForm () {
      this.submitLoading = true
      if (!this.radioVal) {
        this.$msg('请选择一个答案', 'error')
        this.submitLoading = false
        return
      }
      this.$confirm(['投票', '是否选择这条答案'], () => {
        const data = {
          voteId: this.articleData.id,
          optionId: this.radioVal,
          remarks: this.textareaVal
        }
        voteWebVoteUser(data).then(res => {
          this.submitLoading = false
          if (res.code === '000') {
            this.articleData.voteUser.state = 2
            this.radioDisabled = true
            this.textareaDisabled = true
            this.$msg('投票成功', 'success')
          }
        })
      }, () => {
        this.submitLoading = false
      })
    },
    initialData () {
      this.loading = true
      voteWebStatusDetails({ id: this.$route.query.articleId }).then(res => {
        this.loading = false
        if (res.code === '000') {
          const radioList = []
          res.result.itemOneVos.forEach(element => {
            if (res.result.voteStatus === 1 || res.result.voteStatus === 3) {
              radioList.push({ title: element.voteItem, value: element.vid, isRemarks: element.isRemarks, disabled: true })
            } else {
              if (res.result.voteUser.optionId && res.result.voteUser.state === 2) {
                radioList.push({ title: element.voteItem, value: element.vid, isRemarks: element.isRemarks, disabled: true })
              } else {
                radioList.push({ title: element.voteItem, value: element.vid, isRemarks: element.isRemarks })
              }
            }
          })
          this.radioList = radioList
          if (res.result.voteUser.optionId && res.result.voteUser.state === 2) {
            this.radioVal = res.result.voteUser.optionId
          }
          if (res.result.voteUser.remarks) {
            this.textareaDisabled = true
            this.textareaVal = res.result.voteUser.remarks
          }
          const arr = res.result.adminCmsItems
          if (arr[0].url) {
            this.breadcrumbList.push({ name: arr[0].name, path: `${arr[0].url}?id=${arr[0].id}` })
          } else {
            this.breadcrumbList.push({ name: arr[0].name, path: `/list?id=${arr[0].id}` })
          }
          if (arr[1]) {
            if (arr[1].url) {
              this.breadcrumbList.push({ name: arr[1].name, path: `${arr[1].url}?id=${arr[0].id}&cid=${arr[1].id}` })
            } else {
              this.breadcrumbList.push({ name: arr[1].name, path: `/member?id=${arr[0].id}&cid=${arr[1].id}` })
            }
          }
          // this.breadcrumbList.push({ name: arr[0].name, path: `${arr[0].url}?id=${arr[0].id}` })
          // if (arr[1]) {
          //   this.breadcrumbList.push({ name: arr[1].name, path: `${arr[0].url}?id=${arr[0].id}&cid=${arr[1].id}` })
          // }
          this.breadcrumbList.push({ name: '正文', path: '' })
          if (res.result.fileVos !== null) {
            res.result.fileVos.forEach(element => {
              element.type = element.path.substr(element.path.length - 3, element.path.length - 1)
            })
          }
          this.articleData = res.result
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.detail{
  padding-bottom: 30px;
}
.detail-box{
  padding: 40px 80px;
  min-height: 520px;
  background-color: #ffffff;
}
.detail-title{
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #E9E9E9;
  margin-bottom: 40px;
}
.detail-source{
  color: #474747;
  line-height: 20px;
  font-size: 13px;
}
.file-box{
  padding: 20px 0;
}
.file-li{
  padding: 10px 65px 0 25px;
  height: 40px;
  position: relative;
  background-color: #f8f8f8;
  border-radius: 4px;
  margin-bottom: 5px;
}
.file-li:hover{
  background-color: #f0f3fa;
}
.file-title{
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}
.file-li-icon{
  height: 20px;
  width: 20px;
  position: absolute;
  top: 10px;
  right: 25px;
}
.file-li-icon img{
  display: block;
  height: 100%;
  width: 100%;
}
.tb-icon{
  height: 18px;
  width: 18px;
  vertical-align: middle;
  margin-right: 10px;
}
</style>
